import React from "react"

import { ArrowDown, ArrowUp, Cross } from "akar-icons"
import classNames from "classnames"
import NProgress from "nprogress"
import PropTypes from "prop-types"

import AddSection from "../components/cms/AddSection"
import CMSBottomBar from "../components/cms/CMSBottomBar"
import CMSSidebar from "../components/cms/CMSSidebar"
import ConfirmationModal from "../components/cms/ConfirmationModal"
import Loader from "../components/common/Loader"
import Toast, {
  toastMessage,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "../components/common/Toast"
import SEO from "../components/seo"
import { fetchPage, updatePage } from "../graphql"
import RenderBlocks from "../utils/render-blocks"

const CMSPage = ({ location }) => {
  const [isContentUpdated, setIsContentUpdated] = React.useState(false)
  const [token, setToken] = React.useState("")
  const [title, setTitle] = React.useState("")
  const [pageId, setPageId] = React.useState("")
  const [originalSections, setOriginalSections] = React.useState([])
  const [sections, setSections] = React.useState([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false)
  const [sectionToBeRemoved, setSectionToBeRemoved] = React.useState(null)
  const [selectedSection, setSelectedSection] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [isAnnouncementBarExist, setIsAnnouncementBarExist] =
    React.useState(false)

  React.useEffect(() => {
    if (location) {
      const query = new URLSearchParams(location.search)
      setToken(query.get("token"))
      setPageId(query.get("page"))
      getPageData(query.get("page"))
    } else {
      setIsLoading(false)
    }
  }, [location])

  React.useEffect(() => {
    if (selectedSection === null) {
      document.querySelector("body").classList.remove("modal-open")
    } else {
      document.querySelector("body").classList.add("modal-open")
    }
  }, [selectedSection])

  React.useEffect(() => {
    if (sections.length && originalSections.length) {
      if (JSON.stringify(sections) !== JSON.stringify(originalSections)) {
        setIsContentUpdated(true)
      }
    }

    if (sections.length !== originalSections.length) {
      setIsContentUpdated(true)
    }
  }, [sections, originalSections])

  React.useEffect(() => {
    if (sections.some((sec) => sec.type === "AnnouncementBar")) {
      setIsAnnouncementBarExist(true)
    } else {
      setIsAnnouncementBarExist(false)
    }
  }, [sections])

  const getPageData = async (pageId) => {
    setIsLoading(true)
    NProgress.start()
    try {
      const response = await fetchPage(pageId)
      setTitle(response.page.title)
      setOriginalSections(JSON.parse(response.page.content))
      setSections(JSON.parse(response.page.content))
      sections.length === 0 &&
        originalSections.length === 0 &&
        setIsContentUpdated(false)
    } catch (err) {
      console.log(err)
    }
    setIsLoading(false)
    NProgress.done()
  }

  const updateData = async () => {
    NProgress.start()
    try {
      await updatePage(token, pageId, title, JSON.stringify(sections))
      setIsContentUpdated(false)
      toastMessage("Changes have been saved.", TYPE_SUCCESS)
    } catch (err) {
      toastMessage("Changes could not be saved.", TYPE_ERROR)
      console.log(err)
    }
    NProgress.done()
  }

  const removeSection = (index) => {
    const updatedSections = [...sections]
    document.body.style.overflowY = "auto"
    updatedSections.splice(index, 1)
    setIsContentUpdated(true)
    setSections(updatedSections)
  }

  const handleMoveUp = (e, idx) => {
    e.stopPropagation()
    if (idx === 0 || (idx === 1 && isAnnouncementBarExist)) return

    const copySections = [...sections]
    const t = copySections[idx]
    copySections[idx] = copySections[idx - 1]
    copySections[idx - 1] = t
    setSections(copySections)
    setIsContentUpdated(true)
    window.scrollBy({ top: -window.innerHeight, left: 0, behavior: "smooth" })
  }
  const handleMoveDown = (e, idx) => {
    e.stopPropagation()
    if (idx === sections.length - 1) return
    const copySections = [...sections]
    const t = copySections[idx]
    copySections[idx] = copySections[idx + 1]
    copySections[idx + 1] = t
    setSections(copySections)
    setIsContentUpdated(true)
    window.scrollBy({ top: window.innerHeight, left: 0, behavior: "smooth" })
  }

  if (!isLoading) {
    return (
      <>
        <SEO title={"CMS - Update Content and Layout"} path="/cms" />
        <Toast />
        {sections.length > 0 ? (
          sections.map((section, index) => (
            <div
              key={index}
              onClick={() => setSelectedSection(index)}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "Space")
                  setSelectedSection(index)
              }}
              tabIndex={0}
              role="button"
              className="cms-section"
            >
              {RenderBlocks(section)}
              <button
                className="cms-section__remove-btn"
                onClick={(e) => {
                  e.stopPropagation()
                  setIsConfirmationModalOpen(true)
                  document.body.style.overflowY = "hidden"
                  setSectionToBeRemoved(index)
                }}
              >
                <Cross color="black" size="16" />
              </button>
              {section.type !== "AnnouncementBar" && (
                <div className="cms-section__move-btn">
                  <button
                    className={classNames({
                      "cms-section__move-btn__disabled":
                        index === 0 || (index === 1 && isAnnouncementBarExist),
                    })}
                    onClick={(e) => handleMoveUp(e, index)}
                    title={
                      index === 0
                        ? "This section is already at the top"
                        : "Move section up"
                    }
                  >
                    <ArrowUp strokeWidth={2} size={24} />
                  </button>
                  <button
                    className={classNames({
                      "cms-section__move-btn__disabled":
                        index === sections.length - 1,
                    })}
                    onClick={(e) => handleMoveDown(e, index)}
                    title={
                      index === sections.length - 1
                        ? "This section is already at the bottom"
                        : "Move section down"
                    }
                  >
                    <ArrowDown strokeWidth={2} size={24} />
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="empty-section">No section yet, add new section</div>
        )}
        {selectedSection === null && (
          <AddSection sections={sections} setSections={setSections} />
        )}
        {selectedSection !== null && (
          <CMSSidebar
            selectedSection={selectedSection}
            sections={sections}
            setSections={setSections}
            setSelectedSection={setSelectedSection}
          />
        )}
        {isContentUpdated && <CMSBottomBar updateData={updateData} />}
        {isConfirmationModalOpen && (
          <ConfirmationModal
            setIsOpen={setIsConfirmationModalOpen}
            removeSection={removeSection}
            sectionToBeRemoved={sectionToBeRemoved}
            setIsContentUpdated={setIsContentUpdated}
          />
        )}
      </>
    )
  }
  return <Loader />
}

CMSPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default CMSPage
