import React, { useRef, useEffect } from "react"

import PropTypes from "prop-types"

import Button from "../common/Button"
import "./ConfirmationModal.scss"

const ConfirmationModal = ({
  removeSection,
  setIsOpen,
  sectionToBeRemoved,
  setIsContentUpdated,
}) => {
  const ref = useRef()

  const handleOutsideClick = (e) => {
    if (ref.current?.contains(e.target)) {
      return
    }
    document.body.style.overflowY = "auto"
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick)
    return () => {
      document.removeEventListener("click", handleOutsideClick)
    }
  }, [])

  return (
    <div className="confirmation-modal">
      <div className="confirmation-modal__background">
        <div className="confirmation-modal__container" ref={ref}>
          <p className="confirmation-modal__text">
            Are you sure you want to remove this section?
          </p>
          <div className="confirmation-modal__buttons">
            <Button
              onClick={() => {
                document.body.style.overflowY = "auto"
                setIsOpen(false)
              }}
              color="light"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                removeSection(sectionToBeRemoved)
                setIsOpen(false)
                setIsContentUpdated(true)
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmationModal.propTypes = {
  removeSection: PropTypes.func.isRequired,
  setIsContentUpdated: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  sectionToBeRemoved: PropTypes.any,
}

export default ConfirmationModal
