import React, { useContext, useState } from "react"

import { Cross } from "akar-icons"
import NProgress from "nprogress"
import PropTypes from "prop-types"

import { AuthContext } from "../../store/auth"
import { SUPERVISOR_BASE_URL, STORE_ID } from "../../utils"
import Button from "../common/Button"
import Input from "../common/Input"
import Loader from "../common/Loader"

import "./CMSSidebar.scss"

const CMSSidebar = ({
  sections,
  setSections,
  selectedSection,
  setSelectedSection,
}) => {
  const [localSection, setLocalSection] = React.useState({})
  const { setLoader, isLoading } = useContext(AuthContext)
  const [isImage1Uploading, setIsImage1Uploading] = useState(false)

  const pascalCaseTitle = sections[selectedSection].type
  const title = pascalCaseTitle.replace(/([A-Z])/g, " $1")
  const formattedTitle = title.charAt(0).toUpperCase() + title.slice(1)

  const handleKeyDown = React.useCallback(
    (e) => {
      if (e.key === "Escape") {
        setSelectedSection(null)
      }
    },
    [setSelectedSection]
  )

  React.useEffect(() => {
    document.addEventListener("keydown", handleKeyDown)
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [handleKeyDown])

  React.useEffect(() => {
    if ((sections.length, selectedSection !== null)) {
      setLocalSection(sections[selectedSection])
    }
  }, [sections, selectedSection])

  const handleChange = async (e, isFile, isAltText) => {
    let value
    if (isFile) {
      const data = await uploadImage(e.target.files[0], e.target.name)

      if (!data) return

      const key = e.target.name

      value = {
        ...localSection[key],
        url: data.image,
      }
    } else if (isAltText) {
      const key = e.target.name.split(".")[0]
      const keyToBeUpdated = e.target.name.split(".")[1]

      value = {
        ...localSection[key],
        [keyToBeUpdated]: e.target.value,
      }
    } else {
      value = e.target.value
    }

    const key = isAltText ? e.target.name.split(".")[0] : e.target.name

    setLocalSection({
      ...localSection,
      [key]: value,
    })
    setLoader(false)
    setIsImage1Uploading(false)
  }

  const removeImage = (e) => {
    const key = e.target.closest("button").name.split(".")[0]

    setLocalSection({
      ...localSection,
      [key]: "",
    })
  }

  const uploadImage = async (image, key) => {
    const body = new FormData()
    body.append("store_id", STORE_ID)
    body.append("image", image)

    key === "image1" ? setIsImage1Uploading(true) : setLoader(true)
    NProgress.start()
    try {
      const response = await fetch(`${SUPERVISOR_BASE_URL}/api/cms-image/`, {
        method: "POST",
        body: body,
      })
      NProgress.done()
      return await response.json()
    } catch (error) {
      console.log(error)
      NProgress.done()
      setLoader(false)
      setIsImage1Uploading(false)

      return ""
    }
  }

  const renderField = (key) => {
    let buf
    if (key.substring(0, 5) === "image") {
      buf = key
      key = "image"
    }
    switch (key) {
      case "type":
        return <div key={key}></div>
      case "":
        return (
          <div key={key}>
            <Input
              type="text"
              name={key}
              placeholder={key}
              value={localSection[key]}
              onChange={handleChange}
              isBlock
            />
          </div>
        )
      case "body":
        return (
          <div key={key}>
            <Input
              type="textarea"
              name={key}
              placeholder={key}
              rows={7}
              value={localSection[key]}
              onChange={handleChange}
              isBlock
            />
          </div>
        )
      case "image":
        return (
          <div className="image-field" key={buf}>
            {buf === "image1" && isImage1Uploading && <Loader size="small" />}
            {buf !== "image1" && isLoading && <Loader size="small" />}
            {buf !== "image1" && !isLoading && localSection[buf]?.url && (
              <div className="preview-container">
                <img
                  src={localSection[buf].url}
                  className={`${buf === "image2" && "image2"}`}
                  alt="Preview"
                  loading="lazy"
                />
                <button name={`${buf}.remove`} onClick={removeImage}>
                  <Cross color="white" size="16" />
                </button>
              </div>
            )}
            {buf === "image1" && !isImage1Uploading && localSection[buf]?.url && (
              <div className="preview-container">
                <img
                  src={localSection[buf].url}
                  className={`${buf === "image2" && "image2"}`}
                  alt="Preview"
                  loading="lazy"
                />
                <button name={`${buf}.remove`} onClick={removeImage}>
                  <Cross color="white" size="16" />
                </button>
              </div>
            )}
            <Input
              type="text"
              name={`${buf}.alt`}
              value={localSection[buf]?.alt}
              onChange={(e) => handleChange(e, false, true)}
              placeholder="Image Alt Text"
            />
            <Input
              type="file"
              name={buf}
              placeholder={"Upload your image"}
              onChange={(e) => {
                handleChange(e, true)
              }}
              isBlock
            />
            <Input
              type="text"
              name={`${buf}.caption`}
              value={localSection[buf]?.caption}
              onChange={(e) => handleChange(e, false, true)}
              placeholder="Enter caption"
            />
          </div>
        )
      default:
        return (
          <div key={key}>
            <Input
              type="text"
              name={key}
              placeholder={key}
              value={localSection[key]}
              onChange={handleChange}
              isBlock
            />
          </div>
        )
    }
  }

  const updateSection = () => {
    setSelectedSection(null)
    const updatedSections = sections.map((section, index) => {
      if (index === selectedSection) {
        return localSection
      }
      return section
    })
    setSections(updatedSections)
  }

  return (
    <aside className="cms-sidebar">
      <div
        className="cms-sidebar__background"
        onClick={() => setSelectedSection(null)}
        onKeyDown={(e) => {
          if (e.key === "Enter") setSelectedSection(null)
        }}
        tabIndex={0}
        role="button"
      />
      <div className="cms-sidebar__sidebar">
        <div
          className="cms-sidebar__sidebar__close"
          onClick={() => setSelectedSection(null)}
          onKeyDown={(e) => {
            if (e.key === "Enter") setSelectedSection(null)
          }}
          tabIndex={0}
          role="button"
        >
          <Cross />
        </div>
        <div className="cms-sidebar__sidebar__title">
          Update {formattedTitle} Section
        </div>
        <div className="cms-sidebar__sidebar__fields">
          {Object.keys(sections[selectedSection]).map((key) =>
            renderField(key, sections, selectedSection)
          )}
        </div>
        <div className="cart__sidebar__update-button">
          <Button
            color="primary"
            isBlock
            isLarge
            isLoading
            onClick={updateSection}
          >
            Update Section
          </Button>
        </div>
      </div>
    </aside>
  )
}

CMSSidebar.propTypes = {
  sections: PropTypes.array.isRequired,
  setSections: PropTypes.func.isRequired,
  selectedSection: PropTypes.number.isRequired,
  setSelectedSection: PropTypes.func.isRequired,
}

export default CMSSidebar
