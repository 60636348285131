import React from "react"

import PropTypes from "prop-types"

import Button from "../common/Button"

import "./CMSBottomBar.scss"

const CMSBottomBar = ({ updateData }) => {
  return (
    <section className="cms-bottom-bar">
      <div className="cms-bottom-bar__container">
        <div className="cms-bottom-bar__message">
          Content Updated, Hit Save Changes to Update the Site
        </div>
        <div className="cms-bottom-bar__button">
          <Button color="light" onClick={updateData} isBlock isLarge>
            Save Changes
          </Button>
        </div>
      </div>
    </section>
  )
}

CMSBottomBar.propTypes = {
  updateData: PropTypes.func.isRequired,
}

export default CMSBottomBar
