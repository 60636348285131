import React, { useState } from "react"

import { Plus } from "akar-icons"
import PropTypes from "prop-types"

import "./AddSection.scss"

const AddSection = ({ setSections, sections }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const [isAnnouncementBarExist, setIsAnnouncementBarExist] = useState(false)
  const allSections = [
    {
      type: "TitleContent",
      title: "",
      subtitle: "",
      body: "",
    },
    {
      type: "TitleContentImageRight",
      title: "",
      body: "",
      image: {
        url: "",
        alt: "",
      },
    },
    {
      type: "ImageLeftTitleContent",
      title: "",
      body: "",
      image: {
        url: "",
        alt: "",
      },
    },
    {
      type: "TwoImage",
      image1: {
        url: "",
        alt: "",
      },
      image2: {
        url: "",
        alt: "",
      },
    },
    {
      type: "FullImage",
      image: {
        url: "",
        alt: "",
      },
    },
    {
      type: "MediumImage",
      image: {
        url: "",
        alt: "",
      },
    },
    {
      type: "TitleContentButtonImageRight",
      title: "",
      description: "",
      buttonText: "",
      backgroundColor: "",
      textColor: "",
      buttonBackgroundColor: "",
      buttonTextColor: "",
      buttonLink: "",
      image: {
        url: "",
        alt: "",
      },
    },
    {
      type: "SubTitleTitleContentButtonImageRight",
      topText: "",
      title: "",
      description: "",
      buttonText: "",
      backgroundColor: "",
      textColor: "",
      buttonBackgroundColor: "",
      buttonTextColor: "",
      buttonLink: "",
      image: {
        url: "",
        alt: "",
      },
    },
    {
      type: "TitleContentThreeProductsButton",
      title: "",
      description: "",
      productName1: "",
      productRating1: "",
      productSellingPrice1: "",
      productMRP1: "",
      image1: {
        url: "",
        alt: "",
      },
      productName2: "",
      productRating2: "",
      productSellingPrice2: "",
      productMRP2: "",
      image2: {
        url: "",
        alt: "",
      },
      productName3: "",
      productRating3: "",
      productSellingPrice3: "",
      productMRP3: "",
      image3: {
        url: "",
        alt: "",
      },
      cardbuttonText: "",
      cardbuttonLink: "",
      bottomButtonText: "",
      bottomButtonLink: "",
    },
    {
      type: "CollectionBanner",
      title: "",
      subtitle: "",
      description: "",
      buttonText: "",
      buttonLink: "",
      image1: {
        url: "",
        alt: "",
      },
      image2: {
        url: "",
        alt: "",
      },
    },
    {
      type: "Banner",
      title: "",
      discountText: "",
      image1: {
        url: "",
        alt: "",
      },
      buttonText: "",
      buttonLink: "",
    },
    {
      type: "Gallery",
      product1: "",
      image1: {
        url: "",
        alt: "",
      },
      product2: "",
      image2: {
        url: "",
        alt: "",
      },
      product3: "",
      image3: {
        url: "",
        alt: "",
      },
      product4: "",
      image4: {
        url: "",
        alt: "",
      },
      product5: "",
      image5: {
        url: "",
        alt: "",
      },
      product6: "",
      image6: {
        url: "",
        alt: "",
      },
    },
    {
      type: "AnnouncementBar",
      text: "",
      textForLink: "",
      link: "",
      color: "",
      positionTopOrBottom: "",
    },
    {
      type: "Reviews",
      title: "",
      review1: "",
      author1: "",
      review2: "",
      author2: "",
      review3: "",
      author3: "",
      review4: "",
      author4: "",
      review5: "",
      author5: "",
    },
  ]

  const [filteredSections, setFilteredSections] = useState(allSections)

  React.useEffect(() => {
    if (sections.some((section) => section.type === "AnnouncementBar")) {
      setFilteredSections(
        allSections.filter((sec) => sec.type !== "AnnouncementBar")
      )
      setIsAnnouncementBarExist(true)
    } else {
      setFilteredSections(allSections)
      setIsAnnouncementBarExist(false)
    }
  }, [sections])

  const addSection = (type) => {
    setIsExpanded(false)
    const sectionToBeAdded = allSections.filter(
      (section) => section.type === type
    )[0]
    const updatedSections = [...sections]
    let sectionPosition
    if (isAnnouncementBarExist) {
      sectionPosition = 1
    } else {
      sectionPosition = 0
    }

    updatedSections.splice(sectionPosition, 0, sectionToBeAdded)
    setSections(updatedSections)
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <section className="add-section">
      {isExpanded && (
        <div className="add-section__buttons">
          {filteredSections.map((section, i) => {
            const pascalCaseTitle = section.type
            const title = pascalCaseTitle.replace(/([A-Z])/g, " $1")
            const formattedTitle =
              title.charAt(0).toUpperCase() + title.slice(1)

            return (
              <button
                className="add-section__buttons__btn"
                onClick={() => addSection(section.type)}
                key={i}
              >
                {formattedTitle} Section
              </button>
            )
          })}
        </div>
      )}
      <button
        className={`add-section__main-btn ${
          isExpanded && "add-section__main-btn--expanded"
        } `}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Plus strokeWidth={3} size={58} color="var(--color-lightest)" />
      </button>
    </section>
  )
}

AddSection.propTypes = {
  sections: PropTypes.array.isRequired,
  setSections: PropTypes.func.isRequired,
}

export default AddSection
